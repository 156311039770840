body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
