/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scrollbar-width: none;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
::-webkit-scrollbar {
	display: none;
	width: 6px;
	position: absolute;
}
::-webkit-scrollbar-thumb {
	background-color: #111;
	border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #222;
}

body {
	background-color: black;
}

@media (max-width: 720px) {
	.videoAndChat {
		height: 100%;
	}
	.playerAndControls {
		overflow: visible;
	}
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
	.twitchChat {
		width: 300px;
	}
}

@media (max-width: 720px) {
	.playerAndControls {
		height: fit-content;
		overflow: scroll;
	}
}

.transition-enter {
	opacity: 0;
	transform: scale(0.9);
}
.transition-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}
.transition-exit {
	opacity: 1;
}
.transition-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
