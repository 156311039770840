.customPlayer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	color: white;
	display: flex;
	align-items: flex-end;
}
.customPlayer::before,
.customPlayer::after {
	display: "block";
	content: "	";
	position: absolute;
	height: 20%;
	width: 100%;
	z-index: -2;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.8015581232492998) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}
.customPlayer::before {
	top: 0;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.8015581232492998) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}
.playButton {
	display: flex;
	align-items: center;
	justify-content: center;
}
.progressBar {
	width: 98%;
	height: 10px;
	display: flex;
	align-items: center;
	position: relative;
	margin: 0 10px;
}

.currentProgress,
.loadedProgress {
	background-color: white;
	height: 100%;
	width: 100%;
	position: absolute;
	pointer-events: none;
	left: -100%;
	transition: transform 1000ms linear;
	animation: shine 3s forwards infinite;
	border-radius: inherit;
}
.loadedProgress {
	width: 100%;
	left: -100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: -2;
}

.progressBackground {
	width: 100%;
	background-color: rgba(255, 255, 255, 0.35);
	height: 100%;
}

.progressOverflowDiv {
	position: relative;
	width: 100%;
	height: 10px;
	border-radius: 5px;
	overflow: hidden;
	transition: 0.3s ease-in-out;
}

.showTime {
	display: flex;
	justify-content: center;
	width: fit-content;
	height: auto;
	padding: 6px 12px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	transform: translate(100%, -150%);
	border-radius: 5px;
}

.durationBar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	display: flex;
	font-weight: bold;
	margin-left: 15px;
}
.topPlayerOptions {
	position: absolute;
	top: 20px;
	left: 99%;
	transform: translateX(-100%);
	display: flex;
	flex-direction: row-reverse;
	gap: 10px;
}

.videoTitlePlayer {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 90%;
	width: fit-content;
	padding: 25px;
	margin-right: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 800;
	font-size: 30px;
	text-shadow: 2px 2px 2px black;
}

.playbackOption {
	width: 100%;
	padding: 10px 40px;
	display: flex;
	justify-content: center;
	text-align: center;
	transition: 0.3s background;
	cursor: pointer;
}
.playbackOption:hover {
	background-color: #121212;
}

.fullscreenPlayer {
	position: absolute;
	left: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	z-index: 100;
	background-color: #121212;
}

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.live {
	background-color: rgba(0, 0, 0, 0.3);
	padding: 5px 15px;
	color: #f94144;
	border-radius: 5px;
}

.playerSettingsDialog .delay {
	padding: 0px 20px;
}

.controls-enter {
	opacity: 0;
}
.controls-enter-active {
	opacity: 1;
	transition: opacity 200ms, transform 200ms;
}
.controls-exit {
	opacity: 1;
}
.controls-exit-active {
	opacity: 0;
	transition: opacity 200ms, transform 200ms;
}

.sliderFadeIn-enter {
	opacity: 0;
	width: 0;
}

.sliderFadeIn-enter-active {
	opacity: 1;
	width: 50px;
	transition: opacity 200ms, width 2000ms;
}
.sliderFadeIn-exit {
	opacity: 1;
	width: 50px;
}
.sliderFadeIn-exit-active {
	opacity: 0;
	width: 0;
	transition: opacity 200ms, width 2000ms;
}

@media (max-width: 500px) {
	.durationBar {
		font-size: 12px;
	}
}
