.optionsDialog_Window {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.optionsDialog {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px 10px;
	max-width: 500px;
	width: 98%;
	max-height: 90%;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	background-color: #121212;
	border-radius: 5px;
	gap: 10px;
}
.oneOption {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 20px;
	background-color: #0f0f0f;
	border-radius: 5px;
}
