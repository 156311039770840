.historyContainer {
	width: 98vw;
	margin: 0 auto;
	max-width: 500px;
	height: 90vh;
	max-height: 400px;
	background-color: #121212;
	display: flex;
	flex-direction: column;
	padding: 30px 20px;
	align-items: center;
	gap: 10px;
	overflow: scroll;
	border-radius: 5px;
	position: relative;
}
.historyAbsolute {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.historyItem {
	background-color: #0f0f0f;
	padding: 15px;
	width: 100%;
	min-height: 50px;
	border-radius: 5px;
	font-weight: 500;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.historyItem a {
	text-decoration: none;
	color: inherit;
	word-break: break-all;
}
.emptyHistoryText {
	position: absolute;
	display: flex;
	width: 90%;
	border-radius: 10px;
	align-self: center;
	align-items: center;
	font-weight: bold;
	background-color: #0f0f0f;
	padding: 20px;
	justify-self: center;
	font-size: 40px;
	justify-content: center;
	color: #242424;
	pointer-events: none;
	user-select: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
