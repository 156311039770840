@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap"); */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scrollbar-width: none;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
::-webkit-scrollbar {
	display: none;
	width: 6px;
	position: absolute;
}
::-webkit-scrollbar-thumb {
	background-color: #111;
	border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #222;
}

body {
	background-color: black;
}

@media (max-width: 720px) {
	.videoAndChat {
		height: 100%;
	}
	.playerAndControls {
		overflow: visible;
	}
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
	.twitchChat {
		width: 300px;
	}
}

@media (max-width: 720px) {
	.playerAndControls {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		overflow: scroll;
	}
}

.transition-enter {
	opacity: 0;
	-webkit-transform: scale(0.9);
	        transform: scale(0.9);
}
.transition-enter-active {
	opacity: 1;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition: opacity 300ms, -webkit-transform 300ms;
	transition: opacity 300ms, transform 300ms;
	transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}
.transition-exit {
	opacity: 1;
}
.transition-exit-active {
	opacity: 0;
	-webkit-transform: scale(0.9);
	        transform: scale(0.9);
	transition: opacity 300ms, -webkit-transform 300ms;
	transition: opacity 300ms, transform 300ms;
	transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

/* .adminButtonsDiv {
	display: flex;
	justify-content: center;
}
.delayInfoContainer {
	display: flex;
	width: 100%;
	flex-direction: column;
	background-color: black;
}
.delay {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px auto;
}

.twitchLoginButton {
	padding: 10px 0;
	display: flex;
	justify-content: flex-end;
	margin-right: 3%;
}
.addVideo_Form {
	width: 90vw;
	max-width: 500px;
	padding: 30px;
	background-color: #121212;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.adminButtons {
	display: flex;
	gap: 10px;
	margin: 0 auto;
	width: fit-content;
}

.manageIcons {
	display: flex;
}
.isLive {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	border-radius: 5px;
}

.player-wrapper {
	position: relative;
	overflow: hidden;
}

@media (max-width: 720px) {
	.delayInfoContainer {
		flex-direction: column;
	}
	.delay {
		width: 100%;
		border-left: none;
		padding: 10px 0;
	}
	.queueItem .addedBy {
		display: none;
	}
	.adminPanel {
		margin-top: 50px;
	}
} */

.historyContainer {
	width: 98vw;
	margin: 0 auto;
	max-width: 500px;
	height: 90vh;
	max-height: 400px;
	background-color: #121212;
	display: flex;
	flex-direction: column;
	padding: 30px 20px;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
	overflow: scroll;
	border-radius: 5px;
	position: relative;
}
.historyAbsolute {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
.historyItem {
	background-color: #0f0f0f;
	padding: 15px;
	width: 100%;
	min-height: 50px;
	border-radius: 5px;
	font-weight: 500;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.historyItem a {
	text-decoration: none;
	color: inherit;
	word-break: break-all;
}
.emptyHistoryText {
	position: absolute;
	display: flex;
	width: 90%;
	border-radius: 10px;
	align-self: center;
	align-items: center;
	font-weight: bold;
	background-color: #0f0f0f;
	padding: 20px;
	justify-self: center;
	font-size: 40px;
	justify-content: center;
	color: #242424;
	pointer-events: none;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.optionsDialog_Window {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.optionsDialog {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	padding: 20px 10px;
	max-width: 500px;
	width: 98%;
	max-height: 90%;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	background-color: #121212;
	border-radius: 5px;
	grid-gap: 10px;
	gap: 10px;
}
.oneOption {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 20px;
	background-color: #0f0f0f;
	border-radius: 5px;
}

.customPlayer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	color: white;
	display: flex;
	align-items: flex-end;
}
.customPlayer::before,
.customPlayer::after {
	display: "block";
	content: "	";
	position: absolute;
	height: 20%;
	width: 100%;
	z-index: -2;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.8015581232492998) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}
.customPlayer::before {
	top: 0;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.8015581232492998) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}
.playButton {
	display: flex;
	align-items: center;
	justify-content: center;
}
.progressBar {
	width: 98%;
	height: 10px;
	display: flex;
	align-items: center;
	position: relative;
	margin: 0 10px;
}

.currentProgress,
.loadedProgress {
	background-color: white;
	height: 100%;
	width: 100%;
	position: absolute;
	pointer-events: none;
	left: -100%;
	transition: -webkit-transform 1000ms linear;
	transition: transform 1000ms linear;
	transition: transform 1000ms linear, -webkit-transform 1000ms linear;
	-webkit-animation: shine 3s forwards infinite;
	        animation: shine 3s forwards infinite;
	border-radius: inherit;
}
.loadedProgress {
	width: 100%;
	left: -100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: -2;
}

.progressBackground {
	width: 100%;
	background-color: rgba(255, 255, 255, 0.35);
	height: 100%;
}

.progressOverflowDiv {
	position: relative;
	width: 100%;
	height: 10px;
	border-radius: 5px;
	overflow: hidden;
	transition: 0.3s ease-in-out;
}

.showTime {
	display: flex;
	justify-content: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: auto;
	padding: 6px 12px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	-webkit-transform: translate(100%, -150%);
	        transform: translate(100%, -150%);
	border-radius: 5px;
}

.durationBar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	font-weight: bold;
	margin-left: 15px;
}
.topPlayerOptions {
	position: absolute;
	top: 20px;
	left: 99%;
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
	display: flex;
	flex-direction: row-reverse;
	grid-gap: 10px;
	gap: 10px;
}

.videoTitlePlayer {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 90%;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 25px;
	margin-right: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 800;
	font-size: 30px;
	text-shadow: 2px 2px 2px black;
}

.playbackOption {
	width: 100%;
	padding: 10px 40px;
	display: flex;
	justify-content: center;
	text-align: center;
	transition: 0.3s background;
	cursor: pointer;
}
.playbackOption:hover {
	background-color: #121212;
}

.fullscreenPlayer {
	position: absolute;
	left: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	z-index: 100;
	background-color: #121212;
}

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.live {
	background-color: rgba(0, 0, 0, 0.3);
	padding: 5px 15px;
	color: #f94144;
	border-radius: 5px;
}

.playerSettingsDialog .delay {
	padding: 0px 20px;
}

.controls-enter {
	opacity: 0;
}
.controls-enter-active {
	opacity: 1;
	transition: opacity 200ms, -webkit-transform 200ms;
	transition: opacity 200ms, transform 200ms;
	transition: opacity 200ms, transform 200ms, -webkit-transform 200ms;
}
.controls-exit {
	opacity: 1;
}
.controls-exit-active {
	opacity: 0;
	transition: opacity 200ms, -webkit-transform 200ms;
	transition: opacity 200ms, transform 200ms;
	transition: opacity 200ms, transform 200ms, -webkit-transform 200ms;
}

.sliderFadeIn-enter {
	opacity: 0;
	width: 0;
}

.sliderFadeIn-enter-active {
	opacity: 1;
	width: 50px;
	transition: opacity 200ms, width 2000ms;
}
.sliderFadeIn-exit {
	opacity: 1;
	width: 50px;
}
.sliderFadeIn-exit-active {
	opacity: 0;
	width: 0;
	transition: opacity 200ms, width 2000ms;
}

@media (max-width: 500px) {
	.durationBar {
		font-size: 12px;
	}
}

.formatOption {
	padding: 15px 20px;
	cursor: pointer;
}
.formatOption .current {
	background-color: red;
}
.formatOption:hover {
	background-color: #121212;
}

/* .adminList {
	width: fit-content;
	position: relative;
	padding: 15px;
} */
/* 
.adminListArray {
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	z-index: 100;
	max-height: 200px;
	scrollbar-width: 5px;
	overflow: scroll;
	position: absolute;
	left: 0;
	top: 0%;
	transform: translateY(-100%);
	padding: 15px;
	background-color: black;
	border-radius: 5px;
	box-shadow: 0 0 5px black;
	transform-origin: center;
	scrollbar-color: auto;
} */

/* .adminList_Item {
	border-radius: 4px;
	padding: 5px;
	width: 100%;
	font-weight: 700;
} */
/* .insertChat {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #121212;
	width: 100%;
	height: 100%;
	z-index: 2;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
} */

