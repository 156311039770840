.formatOption {
	padding: 15px 20px;
	cursor: pointer;
}
.formatOption .current {
	background-color: red;
}
.formatOption:hover {
	background-color: #121212;
}
